import React, { useReducer } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Input from '../components/Input';
import Button from '../components/Button';
import Loader from '../components/Loader';

const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const isValidEmail = email => {
    if (email.match(re)) {
        return true;
    }
    return false;
};

const PrimeraRecargapage = () => {
    const [state, setState] = useReducer((s, a) => ({ ...s, ...a }), {
        name: null,
        email: null,
        error: null,
        loading: false,
        resolved: false,
    });

    const addEmailToList = e => {
        e.preventDefault();

        if (state.name && state.email && isValidEmail(state.email)) {
            setState({
                ...state,
                loading: true,
                error: null,
            });
            var myHeaders = new Headers();
            myHeaders.append(
                'Authorization',
                'Bearer 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',
            );
            myHeaders.append('Content-Type', 'application/json');
            const contactListId = '357e50d2-b8cd-4270-935e-3550a65f4d95';

            var raw = JSON.stringify({
                list_ids: [contactListId],
                contacts: [{ first_name: state.name.split(' ')[0], email: state.email }],
            });

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            fetch('https://api.sendgrid.com/v3/marketing/contacts', requestOptions)
                .then(response => response.text())
                .then(result => {
                    setState({
                        ...state,
                        loading: false,
                        resolved: true,
                        error: null,
                    });
                })
                .catch(error => {
                    setState({
                        ...state,
                        loading: false,
                        resolved: false,
                        error,
                    });
                });
        } else {
            // show email error
            setState({
                ...state,
                error: 'Completa los campos de nombre y email para continuar.',
            });
        }
    };
    return (
        <Layout>
            <SEO
                title="Aguaman | Primera recarga"
                pathname="/primera-recarga"
                description="Recibe tu botellón a domicilio en 30 minutos. Aprovecha el descuento de BIENVENIDA y prueba nuestro servicio"
                image="https://aguamandelivery.com/static/primera-recarga-4b4ae40af17517dd322211fc414386c7.jpg"
            />
            <section className="value-prop primera-recarga-value-prop">
                <div className="value-prop-content">
                    <div className="main-message">
                        <h1 style={{ lineHeight: 1.2 }}>
                            Olvida la molestia de ir por el{' '}
                            <span className="text-primary" style={{ lineHeight: 1.2 }}>
                                botellón de agua
                            </span>
                        </h1>
                        <p className="main-subtitle">
                            Imagina que quedarte sin agua ya no sea una preocupación.
                            <br />
                            <br />
                            Con nuestro <strong>Servicio Express</strong> recibes el botellón en la
                            puerta de tu domicilio en 30 minutos.
                        </p>
                        <div className="value-prop-buttons">
                            <button
                                className="button button-success"
                                onClick={() => window.scroll({ top: 2000, behavior: 'smooth' })}
                            >
                                Quiero pedir el botellón
                            </button>
                        </div>
                    </div>
                    <div className="main-photo-primera-recarga"></div>
                </div>
            </section>
            <section className="testimonials primera-recarga-testimonials">
                <h1 style={{ padding: '0 28px', marginBottom: 0, lineHeight: 1.15 }}>
                    Si te consideras una persona ocupada ésto te servirá.
                    <br />
                    <span style={{ color: '#1b262c', lineHeight: 1.15 }}>
                        El tiempo ahorrado
                    </span>{' '}
                    lo ocuparás en cosas productivas.
                </h1>
                <div className="testimonial-paragraph" style={{ padding: '0 28px' }}>
                    <h4>San Cristóbal, Táchira 🇻🇪</h4>
                </div>
            </section>
            <section className="primera-recarga-product-benefits">
                <div
                    className="primera-recarga-product-benefits-group"
                    style={{ marginTop: '1em' }}
                >
                    <div className="primera-recarga-product-benefit-1-tagline">
                        <strong>
                            ¿Cansado/a de salir a comprar el botellón?
                            <br />
                            <span className="text-primary">Yo también lo estaba </span>
                        </strong>{' '}
                        <div>
                            <p>
                                Hola, mi nombre es{' '}
                                <a
                                    href="https://www.linkedin.com/in/romer-maldonado/"
                                    target="_blank"
                                    style={{
                                        textDecorationLine: 'underline',
                                        color: '#00a4d3',
                                        fontWeight: 'bold',
                                    }}
                                    rel="noreferrer"
                                >
                                    Romer
                                </a>{' '}
                                y te voy a contar por qué{' '}
                                <strong>inicié este emprendimiento</strong>.
                            </p>
                            <p>
                                Durante mucho tiempo,{' '}
                                <strong>estuve acostumbrado a salir comprar el botellón</strong> y
                                llevarlo hasta mi casa, lo cual era algo que preferia no hacer.
                            </p>
                            <p>
                                Después de tantos años de la misma rutina semanal, decidí crear un{' '}
                                <strong>servicio a domicilio de rápida entrega</strong> para ayudar
                                a personas que tenian el mismo problema que yo.{' '}
                            </p>
                            <p>
                                ¿La calidad del agua?{' '}
                                <strong>
                                    Microfiltración, ultrafiltración, rayos UV y ozonización
                                </strong>{' '}
                                son los procesos que aplicamos para la purificación del agua que
                                envasamos. Algo más importante que eso: El previo lavado y
                                esterilización de los botellones.
                            </p>
                            <p>
                                <strong>Tú también tienes la oportunidad</strong> de olvidar el
                                estrés y la molestia de salir a comprar el botellón.
                            </p>
                        </div>
                    </div>
                    <div className="primera-recarga-product-benefit-1-image"></div>
                </div>
            </section>

            {/* <Newsletter /> */}
            <section className="cta primera-recarga-cta">
                <h1 style={{ color: '#00a4d3' }}>¡Prueba nuestro servicio!</h1>
                <div>
                    <p>
                        Con tu primer pedido <strong>te obsequiamos una bolsa de café ☕</strong> de
                        90 gr (concafé).
                        <br />
                        <br />
                        <strong>Valor: 3.00 USD</strong>
                        <br />
                        <span style={{ fontSize: 14 }}>
                            Incluye la recarga del botellón y el domicilio.
                        </span>
                        <br />
                    </p>
                    {/* <form onSubmit={addEmailToList} className="primera-recarga-form-email">
                        <Input
                            type="text"
                            placeholder="Tu nombre"
                            value={state.name || ''}
                            onChange={e =>
                                setState({ ...state, error: null, name: e.target.value })
                            }
                            disabled={state.loading || state.resolved}
                        />
                        <Input
                            type="email"
                            placeholder="Tu email"
                            value={state.email || ''}
                            onChange={e =>
                                setState({ ...state, error: null, email: e.target.value })
                            }
                            disabled={state.loading || state.resolved}
                        />
                        <Button
                            type="submit"
                            className="button button-success"
                            label="Quiero mi cupón"
                            onClick={addEmailToList}
                            disabled={state.loading || state.resolved}
                        />
                    </form> */}
                    <a
                        href="https://wa.me/584147353821?text=Hola%20aguaman.%20Quiero%20canjear%20mi%20cupón%20de%20Bienvenida%20(AGUAYCAFE)"
                        className="button button-success"
                        target="_blank"
                        disabled={state.loading || state.resolved}
                        style={{ width: 300 }}
                    >
                        Hacer Pedido
                    </a>
                    <div>
                        <p style={{ color: 'green', fontWeight: 'bold' }}>
                            {state.resolved &&
                                '✅ Cupón enviado con éxito. Revisa la bandeja de tu correo electrónico.'}
                        </p>
                        {state.loading && <Loader />}
                        <p style={{ fontSize: '1rem' }}>{state.error}</p>
                    </div>
                    <br />
                    {/* <Link to="/botellon-nuevo">
                        <p style={{ textDecoration: 'underline', fontSize: '1rem' }}>
                            ¿No tienes envase? Presiona aquí
                        </p>
                    </Link> */}
                </div>
            </section>
        </Layout>
    );
};

export default PrimeraRecargapage;
